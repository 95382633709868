export const checkIsDisplayValueRounded = (
  displayValueWithUnit: string,
  datapointValue?: number,
) => {
  let displayValue = displayValueWithUnit.split(' ')[0]
  const isRatio = displayValue?.includes('%')
  if (isRatio) {
    displayValue = displayValue?.slice(0, -1)
  }

  const displayValueDecimalLength = displayValue?.split('.')[1]?.length || 0
  const actualValue = datapointValue ? datapointValue?.toString() : ''
  const actualValueDecimalLength = actualValue.split('.')[1]?.length || 0

  if (isRatio) {
    return displayValueDecimalLength + 2 < actualValueDecimalLength
  }

  return displayValueDecimalLength < actualValueDecimalLength
}
