import { DisplayTheme, ImpactLevel } from '@netpurpose/api'
import { Standard } from '@netpurpose/types'

export type CommonFilterData = {
  displayThemes?: DisplayTheme[] | undefined
  impactLevel?: ImpactLevel | undefined
  sdgGoals?: Standard[] | undefined
}

export const getDataFilteredByTheme =
  <Type extends CommonFilterData>(themes: string[]) =>
  (data: Type[]): Type[] => {
    if (!themes.length) {
      return data
    }
    return data.filter((val) => val.displayThemes?.some((theme) => themes.includes(theme)))
  }

export const getDataFilteredByImpactLevel =
  <Type extends CommonFilterData>(impactLevels: ImpactLevel[]) =>
  (data: Type[]): Type[] => {
    if (!impactLevels.length) {
      return data
    }

    return data.filter((val) => {
      const scope = val.impactLevel
      const operationsOptionActive = impactLevels.includes(ImpactLevel.OPERATIONS)
      const productsAndServicesOptionActive = impactLevels.includes(ImpactLevel.PRODUCTS_SERVICES)
      const valueChainOptionActive = impactLevels.includes(ImpactLevel.VALUE_CHAIN)

      let match = false
      if (operationsOptionActive) {
        match = scope === ImpactLevel.OPERATIONS
      }
      if (!match && productsAndServicesOptionActive) {
        match = scope === ImpactLevel.PRODUCTS_SERVICES
      }
      if (!match && valueChainOptionActive) {
        match = scope === ImpactLevel.VALUE_CHAIN
      }
      return match
    })
  }

export const getDataFilteredBySDG =
  <Type extends CommonFilterData>(sdgs: string[]) =>
  (data: Type[]): Type[] => {
    if (!sdgs.length) {
      return data
    }
    return data.filter((val) => val.sdgGoals?.some((goal) => sdgs.includes(goal.majorCode ?? '')))
  }
